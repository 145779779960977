<template>
  <div id="app">

    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Penugasan Karyawan"
      </v-alert>
    </v-col>

    <v-col v-show="this.valid == true">
      <!-- Alert Data Tersimpan -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="success"
        border="left"
        elevation="4"
        colored-border
        v-model="Alert"
        transition="slide-x-transition"
      >
        <v-icon class="mr-1">check-circle</v-icon>
        Data Berhasil Disimpan
      </v-alert>

      <!-- Alert Data Sudah Terhapus -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="error"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertDataTerhapus"
        transition="slide-x-transition"
      >
        Data Berhasil Dihapus
      </v-alert>

      <!-- Alert Data Berhasil Terubah -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="info"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertBerhasilTerubah"
        transition="slide-x-transition"
      >
        Data Berhasil Diubah
      </v-alert>

      <v-card id="print" tile outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            Data Penugasan
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

          <!-- Menu Export Data -->
          <div class="text-center mx-2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                  <span class="subtitle-1">Export Data</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="142" class="pa-2" v-show="MenuExport">
                <!-- PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXCEL -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>

                <!-- print -->
                <div>
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="print"
                    class="text-capitalize subtitle-1"
                  >
                    Print
                    <v-icon right>mdi-printer</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Pilih Export File
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Tambah Cabang -->
          <v-dialog
            v-model="DialogTambahPenugasan"
            max-width="600px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Btn Tambah Mode Desktop -->
              <v-btn
                class="text-capitalize mr-n2"
                v-show="mobile == false && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                text
                :disabled="loading == true"
              >
                <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                <span class="mr-n1 subtitle-1">Tambah</span>
              </v-btn>

              <!-- Btn Tambah Mode Mobile -->
              <v-btn 
                class="mr-n2"
                v-show="mobile == true && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                text
                fab
                :disabled="loading == true"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card outlined class="rounded-lg">
              <v-toolbar flat height="64">
                <v-toolbar-title class="title">
                  <span class="text-h5 mx-1">{{ formTitlePenugasan }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="KeluarDialogPenugasan"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              
              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <v-row>
                      <!-- <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Kode Nota"
                          color="dark"
                          v-model="editedItem.KodeNota"
                          disabled
                        ></v-text-field>
                      </v-col> -->

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          readonly
                          label="NRK"
                          color="dark"
                          v-model="editedItem.Nrk"
                        >
                          <template v-slot:append-outer>
                            <v-dialog
                              v-model="DialogNrk"
                              max-width="1000px"
                              scrollable
                              persistent
                            >
                              <template v-slot:activator="{on, attrs}">
                                <v-btn
                                  dark
                                  x-small
                                  depressed
                                  height="25"
                                  v-bind="attrs"
                                  v-on="on"
                                  color="red darken-4"
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-card tile outlined class="rounded-lg">
                                <v-toolbar flat height="64">
                                  <v-toolbar-title class="title">
                                    <span class="text-h5 mx-1">{{ formTitleNrk }}</span>
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    icon
                                    @click="KeluarDialogNrk"
                                  >
                                    <v-icon>mdi-window-close</v-icon>
                                  </v-btn>
                                </v-toolbar>

                                <v-divider></v-divider>
                                
                                <v-card-text>
                                  <div class="mr-n3 ml-n3 mb-n2 mt-3">                                          
                                    <v-card outlined class="elevation-3">
                                      <div class="pa-2">
                                        <ejs-grid
                                          ref='gridkaryawanview'
                                          height="184"
                                          gridLines='Both'
                                          id="kodekaryawanview"
                                          :dataSource="karyawan"
                                          :allowPaging="true"
                                          :allowSorting='true'
                                          :allowResizing='true'
                                          :toolbar='toolbarOptions'
                                          :recordDoubleClick="rowDblClickNrk"
                                          :pageSettings='pageSettings'
                                          :rowSelected="rowSelectedNrk" 
                                        >
                                          <e-columns>
                                            <e-column field='Nama' headerText='Nama' width=150></e-column>
                                            <e-column field='NRK' headerText='NRK' width=150></e-column>
                                            <e-column field='jabatan.NamaJabatan' headerText='Jabatan' width=150></e-column>
                                            <e-column field='jabatan.Departemen' headerText='Departemen' width=150></e-column>
                                            <e-column field='jabatan.Divisi' headerText='Divisi' width=150></e-column>
                                          </e-columns>
                                        </ejs-grid>
                                      </div>
                                    </v-card>                                      
                                  </div>     
                                </v-card-text>                                   
                                <v-divider></v-divider>
                                <v-card-actions>
                                  <span class="d-inline-block text-truncate">
                                    <span>Status : <span>{{StatusPilih}}</span></span>
                                  </span>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    depressed
                                    color="red darken-4 mx-n2"
                                    class="text-capitalize"
                                    @click="SimpanNrk"
                                  >
                                    <v-icon left>mdi-check-bold</v-icon>
                                    <span class="mr-1">Pilih</span>
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Nama"
                          color="dark"
                          readonly
                          v-model="editedItem.Nama"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Perusahaan Tujuan"
                          color="dark"
                          v-model="editedItem.PerusahaanTujuan"   
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Lokasi Penugasan"
                          color="dark"
                          v-model="editedItem.LokasiPenugasan"   
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense

                          label="Nomor WO"
                          color="dark"
                          v-model="editedItem.NomorWo"   
                        ></v-text-field>
                      </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Keterangan"
                          color="dark"
                          v-model="editedItem.Keterangan"   
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-dialog
                          v-model="MenuTglEstimasiPergi"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.EstimasiPergi"
                              label="Estimasi Pergi"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.EstimasiPergi"
                            @input="MenuTglEstimasiPergi = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-dialog
                          v-model="MenuTglEstimasiPulang"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.EstimasiPulang"
                              label="Estimasi Pulang"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.EstimasiPulang"
                            @input="MenuTglEstimasiPulang = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-dialog
                          v-model="MenuTglActualPergi"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.ActualPergi"
                              label="Actual Pergi"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.ActualPergi"
                            @input="MenuTglActualPergi = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-dialog
                          v-model="MenuTglActualPulang"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.ActualPulang"
                              label="Actual Pulang"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.ActualPulang"
                            @input="MenuTglActualPulang = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <!-- <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Revisi Ke"
                          color="dark"
                          v-model="editedItem.RevisiKe"   
                        ></v-text-field>
                      </v-col> -->

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Kode Nota Referensi"
                          color="dark"
                          v-model="editedItem.KodeNotaReferensi"   
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  <label>Status : <span>{{Status}}</span></label>
                </span>

                <v-spacer></v-spacer>

                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="Simpan"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridpenugasan'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="penugasanview"
            :dataSource="DataPenugasan"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="145" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='KodeNota' headerText='Kode Nota' textAlign='Left' width=150></e-column>
              <e-column field='NRK' headerText='NRK' width=300></e-column>
              <e-column field='Nama' headerText='Nama' width=160></e-column>
              <e-column field='LokasiPenugasan' headerText='Lokasi Penugasan' width=160></e-column>
              <e-column field='NomorWo' headerText='Nomor WO' width=160></e-column>
              <e-column field='Keterangan' headerText='Keterangan' width=160></e-column>
              <e-column field='EstimasiPergi' headerText='Estimasi Pergi' width=160></e-column>
              <e-column field='EstimasiPulang' headerText='Estimasi Pulang' width=160></e-column>
              <e-column field='ActualPergi' headerText='Actual Pergi' width=160></e-column>
              <e-column field='ActualPulang' headerText='Actual Pulang' width=160></e-column>
              <e-column field='RevisiKe' headerText='Revisi Ke' width=160></e-column>
              <e-column field='KodeNotaReferensi' headerText='Kode Nota Referensi' width=160></e-column>
              <e-column field='DiBuatOleh' headerText='DiBuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatTgl' headerText='DiBuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahOleh' headerText='DiUbahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahTgl' headerText='DiUbahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-card>
    </v-col>

    <v-dialog
      v-model="DialogLihatSuratPenugasan"
      persistent
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="text-h5">
            Surat Penugasan Karyawan
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text outlined class="text-capitalize mx-3" depressed @click="CetakSurat">Cetak</v-btn>
          <v-btn text outlined class="text-capitalize" depressed @click="DialogLihatSuratPenugasan = false">Keluar</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text :class="$vuetify.theme.dark ? '#272727' : 'grey lighten-2'">
          <div style="padding:20px;">
            <v-sheet
              width="794px"
              height="1123px"
              class="mx-auto"
            >
              <div class="borderkertas" id="cetakan">
                <div class="borderdalamkertas">
                  <div class="divhead"  >
                    <div class="row">
                      <div class="columnhead1">
                        <div>
                          <img class="logoTA" src="../assets/TA_Logo.png"/>
                        </div>
                      </div>
                      <div class="columnhead2">
                        <div><b>Body  General Repair</b></div>
                        <div><label>Jl. Bung Tomo No.49 RT.11 Telp.0541 262800, Fax.0541 263842 Samarinda Seberang, email : trinity_auto 1@yahoo.com</label></div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="columnhead3">
                        <div><b>SURAT KETERANGAN PERJALANAN DINAS</b></div>
                      </div>
                      <div class="columnhead4">
                        <div>
                          <table>
                            <tr>
                              <td class="columnhead4sub1"><label>No. Dokumen</label></td>
                              <td class="columnhead4sub2"><label>FORMULIR HRD.25</label></td>
                            </tr>
                            <tr>
                              <td class="columnhead4sub1"><label>Tanggal</label></td>
                              <td class="columnhead4sub2"><label>10 Nopember 12</label></td>
                            </tr>
                            <tr>
                              <td class="columnhead4sub1"><label>Revisi</label></td>
                              <td class="columnhead4sub2"><label>0</label></td>
                            </tr>
                            <tr>
                              <td class="columnhead4sub1"><label>Halaman</label></td>
                              <td class="columnhead4sub2"><label>1-1</label></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr style="height:3px;border:1px double;background-color:black;">
                  <hr style="margin-top:2px; height: 1px;">

                  <div class="isisurat">
                    <div class="row">
                      <div style="width: 50%; text-align: left;">
                        <div>Nomor
                          <label style="margin-left: 10px;">
                            : <label v-text="editedItem.KodeNota"></label>
                          </label>
                        </div>
                      </div>

                      <div style="width: 50%; text-align: right;">
                        <div v-text="'Samarinda, '+DiBuatTgl"></div>
                      </div>
                    </div>

                    <div class="row">
                      <div style="text-justify: inter-word; margin-top: 17px; margin-bottom: 17px;">
                        <label style="margin-left: 40px;">Berdasarkan Perintah Tugas dari Manajemen PT. Trinity Auto, maka dengan ini diberikan Surat Keterangan Perjalanan Dinas kepada karyawan/i tersebut dibawah ;</label>
                      </div>
                    </div>


                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Nama</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text="' '+editedItem.karyawan.Nama"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>NRK</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text="' '+editedItem.Nrk"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Jabatan</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text="' '+editedItem.karyawan.jabatan.NamaJabatan"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Berangkat</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text="' '+'Samarinda'"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Tujuan</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text="' '+editedItem.LokasiPenugasan+' - '+editedItem.PerusahaanTujuan"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Keperluan Tugas</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text=" ' '+editedItem.Keterangan"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="width: 20%; text-align: left;">
                        <div>Tanggal</div>
                      </div>
                      <div style="width: 80%; text-align: left;">
                        <label style="margin-left: 10px;">
                          : <label v-text=" ' '+EstimasiPergi"></label>
                        </label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="text-justify: inter-word; margin-top: 17px; margin-bottom: 17px;">
                        <label>Demikian Surat Keterangan Perjalanan  Dinas  ini diberikan untuk dipergunakan sebagaimana mestinya.</label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="margin-left: 350px; margin-bottom: 17px; width: 100%; text-align: center;">
                        <label>Pimpinan Perusahaan</label>
                      </div>
                    </div>

                    <div class="row">
                      <div style="margin-left: 350px; width: 100%; text-align: center;">
                        <img class="ttd" src="../assets/ttd.png"/>
                      </div>
                    </div>

                    <div class="row">
                      <div style="margin-left: 350px; margin-top: -28px; width: 100%; text-align: center;">
                        <div><label><b><u>Herdaru Purwianto</u></b></label></div>
                        <div><label>Head of Operation Div</label></div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </v-sheet>
          </div>

          <!-- <div style="padding:20px;">
            <v-sheet
              width="794px"
              height="1123px"
              class="pa-12 mx-auto"
              
            >
              <div >
                <div
                  style="border-width:1px;border-style: solid;border-color: #000000;"
                  height="1100px"
                >
                  <div>
                    <v-row no-gutters style="height:70px;">
                      <v-col cols="5">
                        <div style="height:70px;border-width:1px;border-color: #000000;border-right-style: solid;">
                        <v-img
                          style="left:10px;"
                          max-width="220"
                          src="../assets/TA_Logo.png"
                        ></v-img>
                        </div>
                      </v-col>

                      <v-col cols="7">
                        <div style="height:70px;margin-right:-10px;">
                          <center><b><label>Body & General Repair</label></b><br>
                          <p>Jl. Bung Tomo No.49 RT.11 Telp.0541 262800, Fax.0541 263842 Samarinda Seberang, email : trinity_auto 1@yahoo.com</p>
                        </center>
                        </div>
                      </v-col>
                    </v-row>

                    <hr style="color:black;height: 2px;background: black;">

                    <v-row no-gutters style="80px">
                      <v-col cols="5">
                        <div style="height:67px;border-width:1px;border-color: #000000;border-right-style: solid;">
                          <label style="font-size:18px;"><center><b>SURAT KETERANGAN PERJALANAN DINAS</b></center></label>
                        </div>
                      </v-col>

                      <v-col cols="7">
                        <table>
                          <tr>
                            <td id="col1" style="width:150px;"><label style="padding-left:10px;">No. Dokumen</label></td>
                            <td id="col2"><label style="padding-left:10px;">FORMULIR HRD.25</label></td>
                          </tr>
                          <tr>
                            <td id="col1" style="width:150px;"><label style="padding-left:10px;">Tanggal</label></td>
                            <td id="col2"><label style="padding-left:10px;">10  Nopember  12</label></td>
                          </tr>
                          <tr>
                            <td id="col1" style="width:150px;"><label style="padding-left:10px;padding-top:10px">Revisi</label></td>
                            <td id="col2"><label style="padding-left:10px;">0</label></td>
                          </tr>
                          <tr>
                            <td id="col1end" style="width:150px;border-left:1px solid black;"><label style="padding-left:10px;">Halaman</label></td>
                            <td ><label style="padding-left:10px;">1-1</label></td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </div>

                  <hr style="height:4px;border:1px double;background-color:black;">
                  <hr style="margin-top:1px">

                  <div style="padding:20px;">
                    <div
                      height="600px"
                    >
                      <v-row>
                        <v-col cols="8" md="8">
                          <label style="margin-right:70px;" >Nomor</label>
                          <label>:</label>
                          <label style="margin-left:10px" v-text="editedItem.KodeNota"></label>
                        </v-col>
                        <v-col cols="4" md="4">
                          <label  v-text="'Samarinda, '+DiBuatTgl"></label>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-sheet>
                          <label style="margin-left:40px;" >Perintah Tugas dari Manajemen PT. Trinity Auto, maka dengan ini diberikan Surat Keterangan Perjalanan Dinas kepada karyawan/i tersebut dibawah ;</label>
                          </v-sheet>
                        </v-col>
                      </v-row>                      

                      <v-row>
                        <v-col cols="3">
                          <div>
                          <div><label>Nama</label></div>
                          <div><label>NRK</label></div>
                          <div><label>Jabatan</label></div>
                          <div><label>Berangkat</label></div>
                          <div><label>Tujuan</label></div>
                          <div><label>Keperluan Tugas</label></div>
                          <div><label>Tanggal</label></div>
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <div>
                          <div>:<label v-text="' '+editedItem.karyawan.Nama"></label></div>
                          <div>:<label v-text="' '+editedItem.Nrk"></label></div>
                          <div>:<label v-text="' '+editedItem.karyawan.jabatan.NamaJabatan"></label></div>
                          <div>:<label v-text="' '+'Samarinda'"> </label></div>
                          <div>:<label v-text="' '+editedItem.LokasiPenugasan+' - '+editedItem.PerusahaanTujuan"></label></div>
                          <div>:<label v-text=" ' '+editedItem.Keterangan"></label></div>
                          <div>:<label v-text=" ' '+EstimasiPergi"></label></div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div>
                            <label class="text-justify">Demikian Surat Keterangan Perjalanan  Dinas  ini diberikan untuk dipergunakan sebagaimana mestinya.</label>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div style="margin-left:430px;z-index:1;">
                            <label >Pimpinan Perusahaan</label>
                          </div>

                          <div style="z-index:2;margin-top:-20px;margin-left:360px;">
                            <v-img
                              contain
                              max-width="290"
                              src="../assets/ttd.png"
                            ></v-img>
                          </div>

                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="11">
                          <div style="z-index:1;margin-top:-25px;margin-left:390px;">
                            <div style="font-size:10;"><center><label><b><u>Herdaru Purwianto</u></b></label></center></div>
                            <div style="margin-left:40px;">Head of Operation Div</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div> -->
        </v-card-text>
        <!-- <v-card-actions></v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      DialogLihatSuratPenugasan: false,
      mobile:null,
      date: new Date().toISOString().substr(0, 10),
      valid:null,
      passbaru:null,
      EstimasiPergi:'',
      DiBuatTgl:'',
      jabatan:[],
      MenuTglEstimasiPergi:false,
      MenuTglEstimasiPulang:false,
      MenuTglActualPergi:false,
      MenuTglActualPulang:false,
      KodeCabang:null,
      textAktif:true,
      umenu:[],
      SomeStorage:[],
      verifpassbaru:null,
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      karyawan:[],
      DialogNrk: false,
      token:null,
      DialogTambahPenugasan: false,
      form: new FormData,
      editedIndex: -1,
      Permission:[],
      ValidCreate:false,
      defaultItem: {
        id:"",
        KodeNota: "",
        Nrk: "",
        Nama: "",
        PerusahaanTujuan:"",
        LokasiPenugasan: "",
        NomorWo:"",
        Keterangan:"",
        EstimasiPergi:"",
        EstimasiPulang:"",
        ActualPergi:"",
        ActualPulang:"",
        RevisiKe:"",
        KodeNotaReferensi:"",
        karyawan:{
          Nama:"",
          jabatan:{
            NamaJabatan:"",
          }
        }
      },
      editedItem: {
        id:"",
        KodeNota:"",
        Nrk:"",
        Nama:"",
        PerusahaanTujuan:"",
        LokasiPenugasan:"",
        NomorWo:"",
        Keterangan:"",
        EstimasiPergi:"",
        EstimasiPulang:"",
        ActualPergi:"",
        ActualPulang:"",
        RevisiKe:"",
        KodeNotaReferensi:"",
        karyawan:{
          Nama:"",
          jabatan:{
            NamaJabatan:"",
          }
        }
      },
      toolbarOptions: ['Search'],
      DataPenugasan: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // this.saya()
    // await Promise.all([this.saya()])
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
      this.commands.push({buttonOption: { cssClass: "e-flat Lihat", iconCss: "e-icons mata", content:' '} })
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
    
  },
  
  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitlePenugasan() {
      return this.editedIndex === -1 ? 'Tambah Penugasan Baru' : 'Ubah Data Penugasan'
    },
    formTitleNrk(){
      return this.editedIndex === -1 ? 'Pilih NRK Karyawan' : 'Ubah NRK Karyawan'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
    StatusPilih () {
      return this.editedIndex === -1 ? 'Pilih' : 'Pilih'
    },
  },
  
  watch: {
  
      // let a = this.DataPenugasan.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahPenugasan (val) {
      val || this.KeluarDialogPenugasan()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    CetakSurat(){
      let contain = document.getElementById('cetakan')
      // var cln = contain.cloneNode(true);
      // contain.style.height = 'auto'
      // const prtHtml = contain.innerHTML;
      const prtHtml = contain.innerHTML;
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        var is_chrome = Boolean(WinPrint.chrome);
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        WinPrint.document.close();
        if (is_chrome) {
        WinPrint.onload = function() { // wait until all resources loaded 
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();  // change window to mywindow
            WinPrint.close();// change window to mywindow
            };
        }
        else {
            WinPrint.document.close(); // necessary for IE >= 10
            WinPrint.focus(); // necessary for IE >= 10
            WinPrint.print();
            WinPrint.close();
        }
    },
    print(){
      // Get HTML to print from element
        const prtHtml = document.getElementById('print').innerHTML;

        // Get all stylesheets HTML
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

  
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    },
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataPenugasan.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
//   async saya(){
//       await api.get('/menu',{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
//         res=>{
//           if (res) {
//             this.akun = res.data
//             console.log('menu',this.akun)
//             //do noting
//           }
//         }).catch(err=>{
//           // alert(err)
//           if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
//             this.logout()
//           }
//         })
//          if (this.akun.length == 0) {
//             //do nothing
//           }else{
//             let route = this.$route.name
//             let a = this.akun.filter( function(item){return (item.Route == route);} );
//             if (a.length > 0) {
//                this.valid = true
//             }else{
//               this.valid = false
//               this.loading = false
//             }
//             console.log(a)
//           }
//       },
     async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },
    CekNotifTujuan(Nrk){
      let data = this.karyawan.filter(x => x.NRK == Nrk)
      console.log(data)
      let idManagerHRGA = 46
      let result = this.jabatan.filter(x => x.NamaJabatan.toUpperCase().includes("MANAGER OF") && x.Departemen == data[0].jabatan.Departemen)
      result = result.length > 0 ? result[result.length-1].id : idManagerHRGA
      // console.log(result)
      return result
    },

    Simpan(){
      if(this.formTitlePenugasan === "Tambah Penugasan Baru"){
        this.Toast('Data Disimpan')
        setTimeout(() => {
          this.Toast('Approval Di Kirim')
        }, 2500);
        // this.CekNotifTujuan(this.editedItem.Nrk)
         let NotifTujuan = this.CekNotifTujuan(this.editedItem.Nrk)
          api.post('/penugasankaryawan?token='+this.token,{
            KodeNota: this.editedItem.KodeNota,
            Nrk: this.editedItem.Nrk,
            Nama: this.editedItem.Nama,
            PerusahaanTujuan: this.editedItem.PerusahaanTujuan,
            LokasiPenugasan: this.editedItem.LokasiPenugasan,
            NomorWo: this.editedItem.NomorWo,
            Keterangan: this.editedItem.Keterangan,
            EstimasiPergi: this.editedItem.EstimasiPergi,
            EstimasiPulang: this.editedItem.EstimasiPulang,
            ActualPergi: this.editedItem.ActualPergi,
            ActualPulang:this.editedItem.ActualPulang,
            RevisiKe: this.editedItem.RevisiKe,
            KodeNotaReferensi: this.editedItem.KodeNotaReferensi,
            Dari: this.$user.KodeKaryawan,
            NotifTujuan: NotifTujuan,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                // this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitlePenugasan === "Ubah Data Penugasan"){
        this.Toast('Data Diubah')
         api.put("/penugasankaryawan/"+this.editedItem.id+'/?token='+this.token,{
            KodeNota: this.editedItem.KodeNota,
            Nrk: this.editedItem.Nrk,
            Nama: this.editedItem.Nama,
            PerusahaanTujuan:this.editedItem.PerusahaanTujuan,
            LokasiPenugasan: this.editedItem.LokasiPenugasan,
            NomorWo: this.editedItem.NomorWo,
            Keterangan: this.editedItem.Keterangan,
            EstimasiPergi: this.editedItem.EstimasiPergi,
            EstimasiPulang: this.editedItem.EstimasiPulang,
            ActualPergi: this.editedItem.ActualPulang,
            RevisiKe: this.editedItem.RevisiKe,
            KodeNotaReferensi: this.editedItem.KodeNotaReferensi,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogPenugasan()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);

      }else if (args.target.classList.contains("Lihat")) {
        console.log('console lihat',args)
        let DiBuatTgl = args.rowData.DiBuatTgl
        DiBuatTgl = DiBuatTgl.substr(0, 10)
        this.EstimasiPergi = this.FormatTanggal(args.rowData.EstimasiPergi)
        this.DiBuatTgl = this.FormatTanggal(DiBuatTgl)
        this.editedItem = args.rowData
        this.DialogLihatSuratPenugasan = true
        // console.log(args)
        // let a = this.DataPenugasanAll.filter( function(item){return (item.KodeNota == args.rowData.KodeKaryawan);} );
        // this.LihatSuratPenugasan= a[0]
        // console.log(a)
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/penugasankaryawan/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              // this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.editedItem = args.rowData
        this.editedIndex = 1
        this.DialogTambahPenugasan = true
        // this.KodeCabang = args.rowData.KodeCabang
        // let data = args
            // this.editedIndex = 1;
            // console.log(data)
            // this.editedItem = args.rowData.KodeCabang
            // this.DialogTambahPenugasan = true

          // if (args.rowData.Aktif == 0) {
          //   this.dataTemp = args.rowData
          //   this.dialogAktifData = true
          //   }else{
          //       let data = args;
          //       this.editedIndex = 1;
          //       this.Kode = data.rowData.Kode
          //       this.editedItem.Nama = data.rowData.Nama
          //       this.editedItem.Password = data.rowData.Password
          //       this.editedItem.UserMenu = data.rowData.UserMenu
          //       // this.dialogWO = true;
          //   }
      }
    },

    SimpanNrk(){
      this.editedItem.Nrk = this.SomeStorage.Nrk
      this.editedItem.Nama = this.SomeStorage.Nama
      this.DialogNrk = false
    },

    rowDblClickNrk(args){
      this.editedItem.Nrk = args.rowData.NRK
      this.editedItem.Nama = args.rowData.Nama
      this.DialogNrk = false
    },

    rowSelectedNrk(args){
      this.SomeStorage = []
      this.SomeStorage.Nama = args.data.Nama
      this.SomeStorage.Nrk = args.data.NRK
    },

    PdfExport(){
      let data = document.getElementById('penugasanview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        // header: {
        //     fromTop: 0,
        //     height: 50,
        //     contents: [
        //         {
        //             type: 'Image',
        //             src: image,
        //             position: { x: 435, y: 10 },
        //             size: { height: 100, width: 250 },
        //         },
        //         // {
        //         //     type: 'Line',
        //         //     style: { penColor: '#000080', penSize: 3, dashStyle: 'Solid' },
        //         //     points: { x1: 0, y1: 4, x2: 1000, y2: 4 }
        //         // }
        //     ]
        // },
        pageOrientation: 'Landscape',
        fileName:"ReportJabatan.pdf",
      }
      data.columns[4].visible = false
      data.columns[5].visible = false
      data.columns[6].visible = false
      data.columns[7].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridpenugasan.columns[0].visible = false;
      // this.$refs.gridpenugasan.columns[1].visible = true;
      this.$refs.gridpenugasan.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridpenugasan.excelExport();
      // console.log(this.$refs.gridpenugasan)
      // let data = document.getElementById('penugasanview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogPenugasan () {
      this.DialogTambahPenugasan = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    KeluarDialogNrk(){
      this.DialogNrk = false
    },

    AmbilCapital(str) { 
      let newStr = "";
      for (let i = 0; i < str.length; i++) {
          if (str[i].match(/[A-Z]/)) {
              newStr += str[i];
          }
      }
      return newStr;
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      if (this.valid == true) {
        api.get('/penugasankaryawan?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					console.log(res);
          this.DataPenugasan = res.data
          this.loading = false
          this.getbawahan()
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },
    getbawahan(){
      api.get('/GetBawahan/'+this.$user.jabatan.id+'?token='+this.token).then(
        res =>{
          // console.log(res)
          let data = []
          for (let i = 0; i < res.data.length; i++) {
            const ilo = res.data[i];
            for (let e = 0; e < ilo.karyawan.length; e++) {
              const ele = ilo.karyawan[e];
              ele.jabatan = ilo.jabatan
              ele.jabatan.NamaJabatan = ilo.NamaJabatanBawahan
              data.push(ele)
            }
          }
          this.karyawan = data
          // console.log(data)
          this.getjabatan()
          // this.getkaryawan()
        }
      )
    },
    getjabatan(){
      api.get('/jabatan?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res);
          this.jabatan = res.data
				},
				err => {
					console.log(err);
				}
			)
    },

    getkaryawan(){
     if (this.valid == true) {
        api.get('/karyawan?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res.data);
          console.log(this.$user)
          let user = this.$user
          let data
          if (this.$user.jabatan.NamaJabatan.includes("Head of") ) {
            data = res.data.filter(x => x.jabatan != null ? x.jabatan.Divisi == this.AmbilCapital(user.jabatan.NamaJabatan.substr(8)) : x == this.$user)
          }
          else{
            data = res.data.filter(x => x.jabatan != null ? x.jabatan.Departemen == this.$user.jabatan.Departemen : x == this.$user)
          }
          this.karyawan = data
          // this.loading = false
          this.getjabatan()
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    FormatTanggal(x){
      var todayTime = new Date(x);
          var bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
          var month = bulan[todayTime .getMonth()];
          var day = todayTime .getDate();
          var year = todayTime .getFullYear();
          return day + " " + month + " " + year;  
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },  

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
table{
  border-collapse:collapse;
  font-family: arial, sans-serif;
  width: 100%;
}
#col1{
  border-bottom:1px solid;
  border-right:1px solid;
}
#col1end{
  border-right:1px solid;
}
#col2{
  border-bottom:1px solid;
}
.divhead {
  padding: 10px;
}
/* Create two equal columns that floats next to each other */
.columnhead1 {
  float: left;
  width: 40%;
  padding: 5px;
  border-right: 1px solid black;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid black;
}
.columnhead2 {
  text-align: center;
  float: right;
  width: 60%;
  padding: 10px;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid black;
  font-family: 'Calibri', sans-serif;
  font-size: 15px;
}
.columnhead3 {
  text-align: center;
  float: left;
  width: 40%;
  padding: 10px;
  border-right: 1px solid black;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid black;
  font-family: 'Calibri', sans-serif;
  font-size: 20px;
}
.columnhead4 {
  float: right;
  width: 60%;
  /* padding: 10px; */
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: none;
  font-family: 'Calibri', sans-serif;
  font-size: 12px;
}
.columnhead4sub1 {
  padding-bottom: 2px;
  padding-left: 6px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.columnhead4sub2 {
  padding-left: 6px;
  border-bottom: 1px solid black;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.borderkertas {
  border: 1px solid rgb(192, 192, 192);
  width: 794px;
  height: 1123px;
  margin: auto;
}
.borderdalamkertas {
  border: 1px solid black;
  width: 700px;
  height: 950px;
  margin: auto;
  margin-top: 45px;
  padding: 1px;
}
.borderlinehorizontal {
  border-top: 3px solid black;
  border-bottom: 1px solid black;
  border-left: none;
  border-right: none;
}
.logoTA {
  width: 195px;
  margin-bottom: -8px;
}
.ttd {
  width: 280px;
  height: 150px;
  margin-top: -43px;
}
.isisurat {
  padding: 40px;
  /* border: 1px solid black; */
  font-family: 'Calibri', sans-serif;
  font-size: 15px;
}
/* tr td {
  font-size: 9px;
} */
/* th,td {
	border: 1px solid #000000;
  page-break-inside:avoid; page-break-after:auto
}
tr{
  page-break-inside:avoid; page-break-after:auto
} */
/* body {visibility:hidden; }
   #cetakan {visibility:visible; display:fixed; } */
</style>
